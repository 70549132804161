// Production steps of ECMA-262, Edition 6, 22.1.2.1
// Reference: https://people.mozilla.org/~jorendorff/es6-draft.html#sec-array.from
if (!Array.from) {
  Array.from = (function () {
    var toStr = Object.prototype.toString;
    var isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    var toInteger = function (value) {
      var number = Number(value);
      if (isNaN(number)) { return 0; }
      if (number === 0 || !isFinite(number)) { return number; }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    var maxSafeInteger = Math.pow(2, 53) - 1;
    var toLength = function (value) {
      var len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike/*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this;

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError("Array.from requires an array-like object - not null or undefined");
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      var T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError('Array.from: when provided, the second argument must be a function');
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      var k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  }());
}


function ready(fn) {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"){
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1); 
        return null;
    };


class Portfolio  {

    constructor() {

        this.el = {

            topAnimated: document.getElementById('top-animated'),
            bottomAnimated: document.getElementById('bottom-animated'),
            contentAnimated: document.getElementById('content-animated'),
            menuAnimated: document.getElementById('menu-animated'),
            mainContainer: document.getElementById('main-bg-container'),
            topDistance: 0,
            bottomDistance:0,
            contentContainer: document.querySelectorAll('.content-container'),
            modals: document.querySelectorAll('[modal]'),
            modalTriggers: document.querySelectorAll('[data-target-modal]'),
            modalCloseTriggers: document.querySelectorAll('[close-modal]'),
            toggleImg: document.querySelectorAll('[toggle-img]'),
            fixed: document.querySelectorAll('[modal]'),
            toggleImgComing: document.querySelectorAll('[toggle-img-coming]')
        }
    }

    calculateDistance() {
      this.el.topDistance = this.el.topAnimated.clientHeight;
      this.el.bottomDistance = this.el.bottomAnimated.clientHeight;

      //set distances of the main cointainer
      this.el.mainContainer.style.paddingTop = this.el.topDistance + 'px';
      this.el.mainContainer.style.marginBottom = this.el.topDistance + 'px';
    }

    setProperDistance() {
      this.calculateDistance();
      window.addEventListener('resize', () => {
        this.calculateDistance();
      })
    }
    getScrollbarWidth() {
      var outer = document.createElement("div");
      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
  
      document.body.appendChild(outer);
  
      var widthNoScroll = outer.offsetWidth;
      // force scrollbars
      outer.style.overflow = "scroll";
  
      // add innerdiv
      var inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);        
  
      var widthWithScroll = inner.offsetWidth;
  
      // remove divs
      outer.parentNode.removeChild(outer);
  
      return widthNoScroll - widthWithScroll;
  }
    animationEnter(slideTime, opacityTransitionTime) {
      
      //menu container animation
      this.el.menuAnimated.style.transition = `transform .${slideTime}s`;
      this.el.menuAnimated.style.transform = `translateY(${this.el.bottomDistance}px)`;

      //works svg animation
      this.el.bottomAnimated.style.transition = `transform .${slideTime}s`;
      this.el.bottomAnimated.style.transform = `translateY(${this.el.bottomDistance}px)`;

      //about svg animation
      this.el.topAnimated.style.transition = `transform .${slideTime}s`;
      this.el.topAnimated.style.transform = `translateY(-${this.el.topDistance}px)`;

      //content svg animation
      this.el.contentAnimated.style.transition = `opacity .${opacityTransitionTime}s`;
      this.el.contentAnimated.style.transitionDelay = `.${slideTime}s`;
      this.el.contentAnimated.style.opacity = 1;
      }

    animationLeave(slideTime, opacityTransitionTime) {

      //menu container animation
      this.el.menuAnimated.style.transition = `transform .${slideTime}s`;
      this.el.menuAnimated.style.transitionDelay = `.${opacityTransitionTime}s`;
      this.el.menuAnimated.style.transform = `translateY(0px)`;

      //works svg animation
      this.el.bottomAnimated.style.transition = `transform .${slideTime}s`;
      this.el.bottomAnimated.style.transitionDelay = `.${opacityTransitionTime}s`;
      this.el.bottomAnimated.style.transform = `translateY(0px)`;

      //about svg animation
      this.el.topAnimated.style.transition = `transform .${slideTime}s`;
      this.el.topAnimated.style.transitionDelay = `.${opacityTransitionTime}s`;
      this.el.topAnimated.style.transform = `translateY(0px)`;

      //content svg animation
      this.el.contentAnimated.style.transition = `opacity .${opacityTransitionTime}s`;
      this.el.contentAnimated.style.transitionDelay = `0s`;
      this.el.contentAnimated.style.opacity = 0;
      }

    animation() {
      const slideTime = 3;
      const opacityTransitionTime = 6;

      
      document.addEventListener('DOMContentLoaded', () => {
        this.animationEnter(slideTime, opacityTransitionTime)
      })

      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) {
          this.animationLeave(slideTime, opacityTransitionTime)
        } else {
          this.animationEnter(slideTime, opacityTransitionTime)
        }
      })

    }

    modalStateOpen() {
      Array.from(this.el.modalTriggers).forEach(trigger => {

        trigger.addEventListener('click', (e) => {
      
          e.preventDefault();
          e.stopPropagation();
          const target = trigger.getAttribute('data-target-modal');
          const triggerTopDist = trigger.getBoundingClientRect().top;
          const svgTxt = document.querySelector(`[modal="${target}"]`).querySelector('[title-svg]');

          svgTxt.style.transition = 'transform .8s, opacity .1s';
          svgTxt.style.opacity = '0';
          svgTxt.style.transform = `translateY(${triggerTopDist - 80}px)`;

          document.querySelector(`[modal="${target}"]`).classList.add('open');
          document.getElementsByTagName('body')[0].classList.add('open');
          setTimeout(() => {
            svgTxt.style.opacity = '1';
            svgTxt.style.transform = `translateY(0px)`;
            // document.querySelector(`[modal="${target}"]`).style.display = 'block'
          } , 400)
                    


        })

      })
    }


    modalStateClose() {
      const _that = this;

      Array.from(this.el.modalCloseTriggers).forEach(trigger => {

        trigger.addEventListener('click', function(e) {

          e.preventDefault();

          
          let modalName = trigger.closest('[modal]').getAttribute('modal');
          let svgTxt = trigger.closest('[modal]').querySelector('[title-svg]');
          let theTrigger;

          Array.from(_that.el.modalTriggers).forEach(trigger => {
            if (trigger.getAttribute('data-target-modal') === modalName) {
              theTrigger = trigger;
            }
          })


          svgTxt.style.transition = 'transform .2s, opacity .1s';
          svgTxt.style.transform = `translateY(${theTrigger.getBoundingClientRect().top - 80}px)`;
          setTimeout(() => {
              document.getElementsByTagName('body')[0].classList.remove('open');
              this.closest('[modal]').classList.remove('open');
              
              this.closest('[modal]').querySelector('.content-container').scrollTop = 0;
              // this.closest('[modal]').style.display = 'none'

          }, 200)

     

        })

      })
    }


    modals(StickySupport) {
      this.modalStateOpen();
      this.modalStateClose();



      Array.from(this.el.modals).forEach(modal => {
        // let header = modal.querySelector(`[fixed-container]`)
        // if(!StickySupport) {
        //   header.classList.add('sticky-non-support')
        //   modal.addEventListener('scroll', function (e) {
        //     header.style.top = this.scrollTop + 80 + 'px';
        //   })
          
        // } else {
        //   header.classList.add('sticky-supported');
        // }


      })

    }

    toggleImg() {
      let allImg = Array.from(this.el.toggleImg);

      allImg.forEach(function(img, index) {


        img.addEventListener('click', function() {
          this.classList.add('toggle-img')
          
          allImg.forEach(function(item, i) {
             if(i !== index) {
               item.classList.remove('toggle-img')
             } 

          })
        })
      })
    }
    toggleImgComing() {
      let allImg = Array.from(this.el.toggleImgComing);

      allImg.forEach(function(img, index) {


        img.addEventListener('click', function() {
          this.classList.add('toggle-img')
          
          allImg.forEach(function(item, i) {
             if(i !== index) {
               item.classList.remove('toggle-img')
             } 

          })
        })
      })
    }
    init() {

      // let widthScroll = this.getScrollbarWidth();
      // Array.from(this.el.contentContainer).forEach((item, index) => {
      //     item.style.left = '-' + widthScroll + 'px';
      //     item.style.right = '-' + widthScroll + 'px';
      // })

      // window.addEventListener('resize', () => {
      //   let widthScroll = this.getScrollbarWidth();
      //   Array.from(this.el.contentContainer).forEach((item, index) => {
      //       item.style.left = '-' + widthScroll + 'px';
      //       item.style.right = '-' + widthScroll + 'px';
      //   })
      // })
      let widthScroll = this.getScrollbarWidth();
      Array.from(this.el.fixed).forEach((item, index) => {
          
          item.style.paddingRight =  widthScroll + 'px';
      })
  
      window.addEventListener('resize', () => {
        let widthScroll = this.getScrollbarWidth();
        Array.from(this.el.fixed).forEach((item, index) => {
          item.style.paddingRight =  widthScroll + 'px';
        })
      })
      let headerTest = Array.from(document.querySelectorAll(`[fixed-container]`))[0]

      let test = window.getComputedStyle(headerTest).getPropertyValue("position");

      let StickySupport = (test === 'sticky') ? true : false;

      this.setProperDistance();
      this.animation();
      this.modals(StickySupport);
      this.toggleImg();
      this.toggleImgComing();


     }
}


const instance = new Portfolio;
ready(instance.init())


